export const productDatas = [
    {
        id: "temizlik-fircalari",
        data: [
            {
                no: "SP-1015",
                category: "Lüks Oto Fırçası",
                title: "15cm",
                piece: "30 adet",
                weight: "6,5 kg",
                volume: "0,039 m³",
                img: [require('../../static/urunler/1015.jpg'), require('../../static/urunler/1015-1.jpg'), require('../../static/urunler/1015-2.jpg'), require('../../static/urunler/1015-3.jpg')]
            },
            {
                no: "SP-1020",
                category: "Lüks Oto Fırçası",
                title: "20cm",
                piece: "20 adet",
                weight: "5,45 kg",
                volume: "0,043 m³",
                img: [require('../../static/urunler/1020.jpg'), require('../../static/urunler/1020-1.jpg'), require('../../static/urunler/1020-2.jpg'), require('../../static/urunler/1020-3.jpg')]
            },
            {
                no: "SP-1017",
                category: "Lüks Oto Fırçası",
                title: "17cm",
                piece: "25 adet",
                weight: "5,95 kg",
                volume: "0,039 m³",
                img: [require('../../static/urunler/1017-1.jpg')]
            },
            {
                no: "SP-2017",
                category: "Eko Oto Fırçası",
                title: "17cm",
                piece: "25 adet",
                weight: "5,95 kg",
                volume: "0,039 m³",
                img: [require('../../static/urunler/2017.jpg')]
            },
            {
                no: "SP-2015",
                category: "Eko Oto Fırçası",
                title: "15cm",
                piece: "60 adet",
                weight: "13,3 kg",
                volume: "0,075 m³",
                img: [require('../../static/urunler/2015.jpg'), require('../../static/urunler/2015-1.jpg'), require('../../static/urunler/2015-2.jpg'), require('../../static/urunler/2015-3.jpg')]
            },
            {
                no: "SP-2020",
                category: "Eko Oto Fırçası",
                title: "20cm",
                piece: "20 adet",
                weight: "5,65 kg",
                volume: "0,043 m³",
                img: [require('../../static/urunler/2020.jpg'), require('../../static/urunler/2020-1.jpg')]
            },
            {
                no: "SP-1024",
                category: "Kutulu Lüks Oto Fırçası",
                title: "20cm",
                piece: "24 adet",
                weight: "8 kg",
                volume: "0,068 m³",
                img: [require('../../static/urunler/1024.jpg'), require('../../static/urunler/1024-1.jpg'), require('../../static/urunler/1024-2.jpg'), require('../../static/urunler/1024-3.jpg')]
            },
            {
                no: "SP-1026",
                category: "Lüks Dik Fırça",
                title: "15cm",
                piece: "12 adet",
                weight: "3,65 kg",
                volume: "0,027 m³",
                img: [require('../../static/urunler/SP-1026.jpg')]
            },
            {
                no: "SP-1027",
                category: "Lüks Dik Fırça",
                title: "17cm",
                piece: "12 adet",
                weight: "3,93 kg",
                volume: "0,030 m³",
                img: [require('../../static/urunler/1027.jpg')]
            },
            {
                no: "SP-1028",
                category: "Lüks Dik Fırça",
                title: "20cm",
                piece: "12 adet",
                weight: "4,17 kg",
                volume: "0,034 m³",
                img: [require('../../static/urunler/1028.jpg')]
            },
            {
                no: "SP-1029",
                category: "Lüks Dik Fırça",
                title: "21cm",
                piece: "12 adet",
                weight: "4,29 kg",
                volume: "0,035 m³",
                img: [require('../../static/urunler/1029.jpg')]
            },
            {
                no: "SP-1093",
                category: "Flash Lüks Oto Fırçası",
                title: "20cm",
                piece: "12 adet",
                weight: "5,65 kg",
                volume: "0,054 m³",
                img: [require('../../static/urunler/1093.jpg'), require('../../static/urunler/1093-1.jpg')]
            },
            {
                no: "SP-2022",
                category: "Eko Tüylü Yer Fırçası",
                title: "",
                piece: "25 adet",
                weight: "5,75 kg",
                volume: "0,039 m³",
                img: [require('../../static/urunler/2022.jpg')]
            },
            {
                no: "SP-1022",
                category: "Lüks Tüylü Yer Fırçası",
                title: "",
                piece: "25 adet",
                weight: "5,75 kg",
                volume: "0,039 m³",
                img: [require('../../static/urunler/1022.jpg')]
            },
            {
                no: "SP-2021",
                category: "Eko Sert Halı Fırçası",
                title: "",
                piece: "40 adet",
                weight: "8 kg",
                volume: "0,039 m³",
                img: [require('../../static/urunler/2021.jpg')]
            },
            {
                no: "SP-1021",
                category: "Lüks Sert Halı Fırçası",
                title: "",
                piece: "40 adet",
                weight: "8 kg",
                volume: "0,039 m³",
                img: [require('../../static/urunler/1021.jpg'), require('../../static/urunler/1021-1.jpg')]
            },
        ]
    },
    {
        id: "yersil-cekpaslar",
        data: [
            {
                no: "SP-1040",
                category: "Lüks Yer Sileceği 55cm",
                title: "55cm",
                piece: "50 adet",
                weight: "9,85 kg",
                volume: "0,084 m³",
                img: [require('../../static/urunler/1040.jpg'), require('../../static/urunler/1040-1.jpg'), require('../../static/urunler/1040-2.jpg')]
            },
            {
                no: "SP-1039",
                category: "Lüks Yer Sileceği 55cm",
                title: "40cm",
                piece: "50 adet",
                weight: "7,30 kg",
                volume: "0,050 m³",
                img: [require('../../static/urunler/1039.jpg'), require('../../static/urunler/1039-1.jpg'), require('../../static/urunler/1039-2.jpg')]
            },
            {
                no: "SP-2040",
                category: "Eko Yer Sileceği",
                title: "55cm",
                piece: "50 adet",
                weight: "9,85 kg",
                volume: "0,084 m³",
                img: [require('../../static/urunler/2040.jpg'), require('../../static/urunler/2040-2.jpg'), require('../../static/urunler/2040-3.jpg')]
            },
            {
                no: "SP-2039",
                category: "Eko Yer Sileceği",
                title: "40cm",
                piece: "50 adet",
                weight: "7,30 kg",
                volume: "0,050 m³",
                img: [require('../../static/urunler/2039.jpg'), require('../../static/urunler/2039-1.jpg'), require('../../static/urunler/2039-2.jpg')]
            },
        ]
    },
    {
        id: "camsil-cekcekler",
        data: [
            {
                no: "SP-2060",
                category: "Eko Küçük Camsil",
                title: "",
                piece: "60 adet",
                weight: "4 kg",
                volume: "0,030 m³",
                img: [require('../../static/urunler/2060.jpg')]
            },
            {
                no: "SP-2061",
                category: "Eko Orta Camsil",
                title: "",
                piece: "60 adet",
                weight: "4,5 kg",
                volume: "0,043 m³",
                img: [require('../../static/urunler/2061.jpg')]
            },
            {
                no: "SP-2062",
                category: "Eko Büyük Camsil",
                title: "",
                piece: "60 adet",
                weight: "5 kg",
                volume: "0,056 m³",
                img: [require('../../static/urunler/2062.jpg')]
            },
            {
                no: "SP-1060",
                category: "Lüks Küçük Camsil",
                title: "",
                piece: "60 adet",
                weight: "4 kg",
                volume: "0,030 m³",
                img: [require('../../static/urunler/1060.jpg'), require('../../static/urunler/1060-1.jpg'), require('../../static/urunler/1060-2.jpg'), require('../../static/urunler/1060-3.jpg')]
            },
            {
                no: "SP-1061",
                category: "Lüks Orta Camsil",
                title: "",
                piece: "60 adet",
                weight: "4,5 kg",
                volume: "0,043 m³",
                img: [require('../../static/urunler/1061.jpg')]
            },
            {
                no: "SP-1062",
                category: "Lüks Orta Camsil",
                title: "",
                piece: "60 adet",
                weight: "5 kg",
                volume: "0,056 m³",
                img: [require('../../static/urunler/1062.jpg')]
            },
        ]
    },
    {
        id: "supurgeli-faraslar",
        data: [
            {
                no: "SP-2057",
                category: "Eko Mini Faraş",
                title: "",
                piece: "24 adet",
                weight: "11 kg",
                volume: "0,069 m³",
                img: [require('../../static/urunler/2057.jpg')]
            },
            {
                no: "SP-1057",
                category: "Lüks Mini Faraş",
                title: "",
                piece: "24 adet",
                weight: "11 kg",
                volume: "0,069 m³",
                img: [require('../../static/urunler/1057.jpg')]
            },
            {
                no: "SP-2058",
                category: "Eko Star Faraş",
                title: "",
                piece: "24 adet",
                weight: "12 kg",
                volume: "0,069 m³",
                img: [require('../../static/urunler/2058.jpg')]
            },
            {
                no: "SP-1058",
                category: "Eko Star Faraş",
                title: "",
                piece: "24 adet ",
                weight: "12 kg",
                volume: "0,069 m³",
                img: [require('../../static/urunler/1058.jpg')]
            },
            {
                no: "SP-2059",
                category: "Eko Hazneli Faraş",
                title: "",
                piece: "12 adet",
                weight: "7,6 kg",
                volume: "0,060 m³",
                img: [require('../../static/urunler/2059.jpg')]
            },
            {
                no: "SP-1059",
                category: "Lüks Hazneli Faraş",
                title: "",
                piece: "12 adet",
                weight: "7,6 kg",
                volume: "0,060 m³",
                img: [require('../../static/urunler/1059.jpg')]
            },
            {
                no: "SP-2031",
                category: "Eko El Süpürgesi",
                title: "",
                piece: "12 adet",
                weight: "7,25 kg",
                volume: "0,059 m³",
                img: [require('../../static/urunler/2031.jpg')]
            },
            {
                no: "SP-1031",
                category: "Lüks El Süpürgesi",
                title: "",
                piece: "12 adet",
                weight: "7,25 kg",
                volume: "0,059 m³",
                img: [require('../../static/urunler/1031.jpg')]
            },
        ]
    },
    {
        id: "temizlik-kovalari",
        data: [
            {
                no: "SP-1050",
                category: "Lüks Temizlik Kovası",
                title: "15L",
                piece: "25 adet",
                weight: "9,85 kg",
                volume: "0,23 m³",
                img: [require('../../static/urunler/SP-1050.jpg')]
            },
            {
                no: "SP-2050",
                category: "Eko Temizlik Kovası",
                title: "15L",
                piece: "25 adet",
                weight: "28,5 kg",
                volume: "0,23 m³",
                img: [require('../../static/urunler/2050.jpg')]
            },
            {
                no: "SP-1047",
                category: "Renkli Mop Set",
                title: "",
                piece: "1 adet",
                weight: "1,6 kg",
                volume: "0,034 m³",
                img: [require('../../static/urunler/SP-1047.jpg')]
            },
            {
                no: "SP-1048",
                category: "Siyah Mop Set",
                title: "",
                piece: "1 adet",
                weight: "1,6 kg",
                volume: "0,034 m³",
                img: [require('../../static/urunler/1048.jpg')]
            },
        ]
    },
    {
        id: "mop-basliklar",
        data: [
            {
                no: "STR-101",
                category: "Microfiber Örgü",
                title: "125gr",
                piece: "48 adet",
                weight: "6,6 kg",
                volume: "0,079 m³",
                img: [require('../../static/urunler/101.jpg'), require('../../static/urunler/101-1.jpg')]
            },
            {
                no: "STR-102",
                category: "Microfiber Örgü",
                title: "150gr",
                piece: "36 adet",
                weight: "5,89 kg",
                volume: "0,065 m³",
                img: [require('../../static/urunler/102.jpg'), require('../../static/urunler/102-1.jpg')]
            },
            {
                no: "STR-103",
                category: "Micropamuk Örgü",
                title: "134gr",
                piece: "48 adet",
                weight: "6,89 kg",
                volume: "0,060 m³",
                img: [require('../../static/urunler/103.jpg'), require('../../static/urunler/103-1.jpg'), require('../../static/urunler/103-2.jpg'), require('../../static/urunler/103-3.jpg')]
            },
            {
                no: "STR-104",
                category: "Micropamuk Örgü",
                title: "160gr",
                piece: "36 adet",
                weight: "6,16 kg",
                volume: "0,053 m³",
                img: [require('../../static/urunler/104.jpg'), require('../../static/urunler/104-1.jpg')]
            },
            {
                no: "STR-105",
                category: "Micropamuk Islak",
                title: "230gr",
                piece: "36 adet",
                weight: "8,73 kg",
                volume: "0,059 m³",
                img: [require('../../static/urunler/105.jpg'), require('../../static/urunler/105-1.jpg')]
            },
            {
                no: "STR-106",
                category: "Pamuklu Mop",
                title: "160gr",
                piece: "48 adet",
                weight: "8,25 kg",
                volume: "0,075 m³",
                img: [require('../../static/urunler/106.jpg'), require('../../static/urunler/106-1.jpg')]
            },
            {
                no: "STR-107",
                category: "Pamuklu Mop Plus",
                title: "210gr",
                piece: "36 adet",
                weight: "8,1 kg",
                volume: "0,071 m³",
                img: [require('../../static/urunler/107.jpg'), require('../../static/urunler/107-1.jpg')]
            },
            {
                no: "STR-108",
                category: "Pamuklu Islak Mop",
                title: "260gr",
                piece: "36 adet",
                weight: "9,94 kg",
                volume: "0,076 m³",
                img: [require('../../static/urunler/108.jpg'), require('../../static/urunler/108-1.jpg')]
            },
            {
                no: "STR-109",
                category: "Döner Mop Yedek",
                title: "126gr",
                piece: "60 adet",
                weight: "8,24 kg",
                volume: "0,090 m³",
                img: [require('../../static/urunler/109.jpg'), require('../../static/urunler/109-1.jpg')]
            },
            {
                no: "STR-120",
                category: "Döner Mop Yedek Set",
                title: "",
                piece: "24 adet",
                weight: "12,84 kg",
                volume: "0,101 m³",
                img: [require('../../static/urunler/120.jpg'), require('../../static/urunler/120-1.jpg')]
            },
        ]
    },
    {
        id: "diger-urunler",
        data: [
            {
                no: "SP-2043",
                category: "Eko Tekli Gırgır",
                title: "",
                piece: "120 adet",
                weight: "7,75 kg",
                volume: "0,061 m³",
                img: [require('../../static/urunler/2043.jpg'), require('../../static/urunler/2043-1.jpg'), require('../../static/urunler/2043-2.jpg'), require('../../static/urunler/2043-3.jpg')]
            },
            {
                no: "SP-1043",
                category: "Lüks Tekli Gırgır",
                title: "",
                piece: "120 adet",
                weight: "7,75 kg",
                volume: "0,061 m³",
                img: [require('../../static/urunler/1043.jpg'), require('../../static/urunler/1043-1.jpg')]
            },
            {
                no: "SP-2044",
                category: "Eko Çiftli Gırgır",
                title: "",
                piece: "120 adet",
                weight: "11,5 kg",
                volume: "0,012 m³",
                img: [require('../../static/urunler/2044.jpg'), require('../../static/urunler/2044-1.jpg'), require('../../static/urunler/2044-2.jpg'), require('../../static/urunler/2044-3.jpg')]
            },
            {
                no: "SP-144",
                category: "Lüks Çiftli Gırgır",
                title: "",
                piece: "120 adet",
                weight: "11,5 kg",
                volume: "0,012 m³",
                img: [require('../../static/urunler/1044.jpg'), require('../../static/urunler/1044-1.jpg'), require('../../static/urunler/1044-2.jpg'), require('../../static/urunler/1044-3.jpg')]
            },
            {
                no: "SP-2085",
                category: "Eko Askı 6’lı",
                title: "",
                piece: "40 adet",
                weight: "10,8 kg",
                volume: "0,094 m³",
                img: [require('../../static/urunler/2085.jpg'), require('../../static/urunler/2085-1.jpg'), require('../../static/urunler/2085-2.jpg')]
            },
            {
                no: "SP-1085",
                category: "Lüks Askı 6’lı",
                title: "",
                piece: "40 adet",
                weight: "10,8 kg",
                volume: "0,094 m³",
                img: [require('../../static/urunler/1085.jpg')]
            },
            {
                no: "SP-3085",
                category: "Kristal Askı 6’lı",
                title: "",
                piece: "40 adet",
                weight: "10,8 kg",
                volume: "0,094 m³",
                img: [require('../../static/urunler/3085.jpg')]
            },
            {
                no: "SP-2097",
                category: "Eko Faraş",
                title: "",
                piece: "120 adet",
                weight: "8 kg",
                volume: "0,069 m³",
                img: [require('../../static/urunler/2097.jpg'), require('../../static/urunler/2097-2.jpg')]
            },
            {
                no: "SP-1097",
                category: "Lüks Faraş",
                title: "",
                piece: "120 adet",
                weight: "8 kg",
                volume: "0,069 m³",
                img: [require('../../static/urunler/1097-1.jpg')]
            },
            {
                no: "SP-2067",
                category: "Eko WC Fırça Seti",
                title: "",
                piece: "30 adet",
                weight: "5,9 kg",
                volume: "0,102 m³",
                img: [require('../../static/urunler/2067-1.jpg'), require('../../static/urunler/2067-2.jpg')]
            },
            {
                no: "SP-1067",
                category: "Lüks WC Fırça Seti",
                title: "",
                piece: "30 adet",
                weight: "5,9 kg",
                volume: "0,102 m³",
                img: [require('../../static/urunler/1067.jpg')]
            },
            {
                no: "SP-1095",
                category: "Lüks BBQ Yelpazesi",
                title: "",
                piece: "60 adet",
                weight: "2,22 kg",
                volume: "0,010 m³",
                img: [require('../../static/urunler/1095.jpg')]
            },
            {
                no: "SP-1066",
                category: "Lüks Maşrapa",
                title: "",
                piece: "144 adet",
                weight: "12 kg",
                volume: "0,11 m³",
                img: [require('../../static/urunler/1066.jpg'), require('../../static/urunler/1066-1.jpg'), require('../../static/urunler/1066-2.jpg')]
            },
        ]
    }
]