import React from 'react';
import productGroupImage1 from '../../static/temizlik-fircasi.jpg';
import productGroupImage2 from '../../static/yersil-paspas.jpg';
import productGroupImage3 from '../../static/camsil-cekcek.jpg';
import productGroupImage4 from '../../static/supurgeli-faraslar.jpg';
import productGroupImage5 from '../../static/temizlik-kovalari.jpg';
import productGroupImage6 from '../../static/mop-basliklar.jpg';
import productGroupImage7 from '../../static/cesitli-urunler.jpg';
import ProductBox from '../../components/productBox/ProductBox';
import { ArrowRightIcon } from '../../utils/IconUtils';
import { useNavigate } from 'react-router-dom';

import styles from './ProductsPage.module.scss';

const ProductsPage = () => {

    const navigate = useNavigate();

    const productBoxDatas = [
        { id: 0, title: "TEMİZLİK FIRÇALARI", subTitle: "Çöp Poşetleri", image: productGroupImage1, url: '/urunler/temizlik-fircalari' },
        { id: 1, title: "YERSİL ÇEKPASLAR", image: productGroupImage2, url: '/urunler/yersil-cekpaslar' },
        { id: 2, title: "CAMSİL ÇEKÇEKLER", image: productGroupImage3, url: '/urunler/camsil-cekcekler' },
        { id: 3, title: "SÜPÜRGELİ FARAŞLAR", image: productGroupImage4, url: '/urunler/supurgeli-faraslar' },
        { id: 4, title: "TEMİZLİK KOVALARI", image: productGroupImage5, url: '/urunler/temizlik-kovalari' },
        { id: 4, title: "MOP BAŞLIKLARI", image: productGroupImage6, url: '/urunler/mop-basliklar' },
        { id: 4, title: "DİGER ÜRÜNLER", image: productGroupImage7, url: '/urunler/diger-urunler' },
    ]

    return (
        <div className={styles.productsPageContainer}>
            <div className={styles.breadcrumbContainer}>
                <div className={styles.breadcrumbMainContainer}>
                    <span onClick={() => navigate(`/`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>Anasayfa</span>
                    <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                    <span style={{ fontSize: "14px", fontWeight: 600, color: "#f2f2f2" }}>ÜRÜNLER</span>
                </div>
            </div>
            <div className={styles.pageTitleContainer}>
                <h1>ÜRÜNLER</h1>
            </div>
            <div className={styles.productsContainer}>
                <div className={styles.productsMainContainer}>
                    { productBoxDatas.map(val => ( <ProductBox key={val.id} title={val.title} image={val.image} url={val.url} /> )) }
                </div>
            </div>
        </div>
    )
}

export default ProductsPage;