import React from 'react';
import { ArrowRightIcon } from '../../utils/IconUtils';
import { useNavigate } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ category, title, name }) => {
    const navigate = useNavigate();

    const getTitle = (id) => {
        switch (id) {
            case "temizlik-fircalari":
                return "Temizlik Fırçaları";
            case "yersil-cekpaslar":
                return "Yersil Çekpaslar";
            case "camsil-cekcekler":
                return "Camsil Çekçekler";
            case "supurgeli-faraslar":
                return "Süpürgeli Faraşlar"
            case "temizlik-kovalari":
                return "Temizlik Kovalar"
            case "mop-basliklar":
                return "Mop Başlıkları"
            case "diger-urunler":
                return "Diğer Ürünler"
            default:
              return "Temizlik Fırçaları"
        }
    }
    
    return (
        <div className={styles.breadcrumbContainer}>
            <div className={styles.breadcrumbMainContainer}>
                <span onClick={() => navigate(`/`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>Anasayfa</span>
                <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                <span onClick={() => navigate(`/urunler`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>Urunler</span>
                <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                <span onClick={() => navigate(`/urunler/${name}`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>{getTitle(name)}</span>
                <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                <span style={{ fontSize: "14px", fontWeight: 600, color: "#f2f2f2" }}>{`${category} - ${title}`}</span>
            </div>
        </div>
    )
}

export default Breadcrumb