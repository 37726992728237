import React, { useState } from 'react';
import Menu from '../menu/Menu';
import { MenuIcon } from '../../utils/IconUtils';
import SideBar from '../sideBar/SideBar';
import { Link } from 'react-router-dom';
import logo from '../../static/logo.png';

import styles from './Header.module.scss';

const Header = () => {

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const menuItems = [
        {
            id: 0,
            title: "ANASAYFA",
            href: "/"
        },
        {
            id: 1,
            title: "KURUMSAL",
            href: "/kurumsal",
        },
        {
            id: 2,
            title: "ÜRÜNLER",
            href: "/urunler"
        },
        {
            id: 3,
            title: "ÜRETİM",
            href: "/uretim"
        },
        {
            id: 4,
            title: "İLETİŞİM",
            href: "/iletisim"
        },
    ] 

    const closeFunction = () => {
        setIsOpenMenu(false);
    }

    return (
        <>
            <div className={styles.headerContainer}>
                <div className={styles.mainContainer}>
                    <div style={{ cursor: "pointer" }}>
                        <img style={{ width: "180px", height: "100%" }} src={logo} alt='' />
                    </div>
                    <div className={styles.menuContainer}>
                        <Menu data={menuItems} />
                    </div>
                    <div onClick={() => setIsOpenMenu(true)} className={styles.menuIconContainer}>
                        <MenuIcon />
                    </div>
                </div>
            </div>
            <SideBar 
                open={isOpenMenu} 
                closeFunction={closeFunction}
                title={"MENÜLER"}
                align={"right"}
            >
                <div className={styles.sideMenuContainer}>
                    {
                        menuItems.map((val) => (
                            <div onClick={() => setIsOpenMenu(false)} key={val.id} className={styles.sideMenuItem}>
                                <Link style={{ textDecoration: "none" }} className={styles.item} to={val.href}>
                                    {val.title}
                                </Link>
                            </div>
                        ))
                    }
                    <div onClick={() => setIsOpenMenu(false)} className={styles.sideMenuItem}>
                        <Link className={styles.item} to={"tel:+902128868545"}>
                            +90 212 886 85 45
                        </Link>
                    </div>
                    <div onClick={() => setIsOpenMenu(false)} className={styles.sideMenuItem}>
                        <Link className={styles.item} to={"mailto:info@zerplast.com.tr"}>
                            info@zerplast.com.tr
                        </Link>
                    </div>
                </div>
            </SideBar>
        </>
    )
}

export default Header