import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductBoxWithDetail from '../../components/productBoxWithDetail/ProductBoxWithDetail';
import { ArrowRightIcon } from '../../utils/IconUtils';
import { productDatas } from '../../components/mock/MockData';

import styles from './ProductPage.module.scss';

const ProductPage = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    // const productDatas = [
    //     {
    //         id: "ev-tipi-cop-posetleri",
    //         data: [
    //             {
    //                 no: 101,
    //                 category: "EV TİPİ",
    //                 title: "MİNİ BOY",
    //                 product: "40x50 cm - 5 mikron",
    //                 roll: "80 gram - 40 torba",
    //                 parcel: "50 rulo - 0,00122 m³",
    //                 palette: "180 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 102,
    //                 category: "EV TİPİ",
    //                 title: "ORTA BOY",
    //                 product: "55x60 cm - 5 mikron",
    //                 roll: "60 gram - 20 torba",
    //                 parcel: "50 rulo - 0,0131 m³",
    //                 palette: "180 koli",
    //                 img: productImage2
    //             },
    //             {
    //                 no: 104,
    //                 category: "EV TİPİ",
    //                 title: "BÜYÜK BOY",
    //                 product: "65x80 cm - 6 mikron",
    //                 roll: "60 gram - 10 torba",
    //                 parcel: "50 rulo - 0,0117 m³",
    //                 palette: "180 koli",
    //                 img: productImage3
    //             },
    //             {
    //                 no: 106,
    //                 category: "EV TİPİ",
    //                 title: "BATTAL BOY",
    //                 product: "75x90 cm - 11 mikron",
    //                 roll: "140 gram - 10 torba",
    //                 parcel: "50 rulo - 0,0211 m³",
    //                 palette: "120 koli",
    //                 img: productImage4
    //             },
    //             {
    //                 no: 108,
    //                 category: "EV TİPİ",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 12 mikron",
    //                 roll: "200 gram - 10 torba",
    //                 parcel: "25 rulo - 0,0146 m³",
    //                 palette: "144 koli",
    //                 img: productImage5
    //             },
    //         ]
    //     },
    //     {
    //         id: "profesyonel-cop-posetleri",
    //         data: [
    //             {
    //                 no: 201,
    //                 category: "EKONOMİK",
    //                 title: "BATTAL BOY",
    //                 product: "75x90 cm - 16 mikron",
    //                 roll: "200 gram - 10 torba",
    //                 parcel: "20 rulo - 0,00146 m³",
    //                 palette: "120 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 203,
    //                 category: "EKONOMİK",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 18,5 mikron",
    //                 roll: "300 gram - 10 torba",
    //                 parcel: "20 rulo - 0,0146 m³",
    //                 palette: "144 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 110,
    //                 category: "EKONOMİK",
    //                 title: "HANTAL BOY",
    //                 product: "100x150 cm - 22 mikron",
    //                 roll: "600 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0146 m³",
    //                 palette: "120 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 221,
    //                 category: "KONTEYNER",
    //                 title: "120L.",
    //                 product: "90x125 cm - 38,5 mikron",
    //                 roll: "800 gram - 10 torba",
    //                 parcel: "10 rulo - 0,00220 m³",
    //                 palette: "90 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 207,
    //                 category: "KONTEYNER",
    //                 title: "240L.",
    //                 product: "120x150 cm - 24 mikron",
    //                 roll: "800 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0316 m³",
    //                 palette: "90 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 223,
    //                 category: "KONTEYNER",
    //                 title: "240L. EXTRA",
    //                 product: "120x150 cm - 30 mikron",
    //                 roll: "900 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0220 m³",
    //                 palette: "90 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 209,
    //                 category: "PROFESYONEL",
    //                 title: "ORTA BOY",
    //                 product: "55x60 cm - 13 mikron",
    //                 roll: "160 gram - 20 torba",
    //                 parcel: "25 rulo - 0,00146 m³",
    //                 palette: "120 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 211,
    //                 category: "PROFESYONEL",
    //                 title: "BÜYÜK BOY",
    //                 product: "65x80 cm - 16,5 mikron",
    //                 roll: "160 gram - 10 torba",
    //                 parcel: "25 rulo - 0,0211 m³",
    //                 palette: "120 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 213,
    //                 category: "PROFESYONEL",
    //                 title: "BATTAL BOY",
    //                 product: "75x90 cm - 24 mikron",
    //                 roll: "300 gram - 10 torba",
    //                 parcel: "20 rulo - 0,0131 m³",
    //                 palette: "144 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 301,
    //                 category: "PROFESYONEL-EXTRA",
    //                 title: "BATTAL BOY",
    //                 product: "75x90 cm - 32 mikron",
    //                 roll: "400 gram - 10 torba",
    //                 parcel: "20 rulo - 0,00211 m³",
    //                 palette: "96 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 215,
    //                 category: "PROFESYONEL-EXTRA",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 21,5 mikron",
    //                 roll: "350 gram - 10 torba",
    //                 parcel: "20 rulo - 0,0158 m³",
    //                 palette: "128 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 217,
    //                 category: "PROFESYONEL-EXTRA",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 25 mikron",
    //                 roll: "400 gram - 10 torba",
    //                 parcel: "20 rulo - 0,0191 m³",
    //                 palette: "120 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 303,
    //                 category: "PROFESYONEL-STRONG",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 31 mikron",
    //                 roll: "500 gram - 10 torba",
    //                 parcel: "20 rulo - 0,00213 m³",
    //                 palette: "84 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 309,
    //                 category: "PROFESYONEL-COLD",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 37 mikron",
    //                 roll: "600 gram - 10 torba",
    //                 parcel: "20 rulo - 0,0220 m³",
    //                 palette: "72 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 311,
    //                 category: "PROFESYONEL-HEAVY",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 50 mikron",
    //                 roll: "800 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0214 m³",
    //                 palette: "90 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 219,
    //                 category: "PROFESYONEL",
    //                 title: "HANTAL BOY",
    //                 product: "100x150 cm - 29 mikron",
    //                 roll: "800 gram - 10 torba",
    //                 parcel: "10 rulo - 0,00189 m³",
    //                 palette: "120 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 313,
    //                 category: "PROFESYONEL-HEAVY",
    //                 title: "HANTAL BOY",
    //                 product: "100x150 cm - 36 mikron",
    //                 roll: "1000 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0210 m³",
    //                 palette: "96 koli",
    //                 img: productImage1
    //             },
    //         ]
    //     },
    //     {
    //         id: "astarli-cop-posetleri",
    //         data: [
    //             {
    //                 no: 400,
    //                 category: "ASTARLI",
    //                 title: "ÇOK AMAÇLI",
    //                 product: "70x100 cm - 62 mikron",
    //                 roll: "640 gram - 8 torba",
    //                 parcel: "18 rulo - 0,00307 m³",
    //                 palette: "63 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 401,
    //                 category: "ASTARLI",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 31 mikron",
    //                 roll: "1000 gram - 20 torba",
    //                 parcel: "10 rulo - 0,0221 m³",
    //                 palette: "84 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 402,
    //                 category: "ASTARLI-EXTRA",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 43 mikron",
    //                 roll: "1400 gram - 20 torba",
    //                 parcel: "9 rulo - 0,0220 m³",
    //                 palette: "72 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 403,
    //                 category: "ASTARLI-STRONG",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 55,5 mikron",
    //                 roll: "1800 gram - 20 torba",
    //                 parcel: "7 rulo - 0,00262 m³",
    //                 palette: "64 koli",
    //                 img: productImage1
    //             },
    //         ]
    //     },
    //     {
    //         id: "buzgulu-cop-posetleri",
    //         data: [
    //             {
    //                 no: 501,
    //                 category: "BÜZGÜLÜ-LİMON-KOKULU",
    //                 title: "ORTA BOY",
    //                 product: "55x60 cm - 17,5 mikron",
    //                 roll: "120 gram - 10 torba",
    //                 parcel: "50 rulo - 0,00242 m³",
    //                 palette: "96 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 502,
    //                 category: "BÜZGÜLÜ-ÇİÇEK-KOKULU",
    //                 title: "ORTA BOY",
    //                 product: "55x60 cm - 17,5 mikron",
    //                 roll: "120 gram - 10 torba",
    //                 parcel: "50 rulo - 0,00242 m³",
    //                 palette: "96 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 503,
    //                 category: "BÜZGÜLÜ-LAVANTA-KOKULU",
    //                 title: "ORTA BOY",
    //                 product: "55x60 cm - 17,5 mikron",
    //                 roll: "120 gram - 10 torba",
    //                 parcel: "50 rulo - 0,00242 m³",
    //                 palette: "96 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 504,
    //                 category: "BÜZGÜLÜ-LİMON-KOKULU",
    //                 title: "BÜYÜK BOY",
    //                 product: "65x70 cm - 13 mikron",
    //                 roll: "120 gram - 10 torba",
    //                 parcel: "50 rulo - 0,00206 m³",
    //                 palette: "96 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 505,
    //                 category: "BÜZGÜLÜ-ÇİLEK-KOKULU",
    //                 title: "BÜYÜK BOY",
    //                 product: "65x70 cm - 13 mikron",
    //                 roll: "120 gram - 10 torba",
    //                 parcel: "50 rulo - 0,00206 m³",
    //                 palette: "96 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 506,
    //                 category: "BÜZGÜLÜ-LAVANTA-KOKULU",
    //                 title: "BÜYÜK BOY",
    //                 product: "65x70 cm - 13 mikron",
    //                 roll: "120 gram - 10 torba",
    //                 parcel: "50 rulo - 0,00206 m³",
    //                 palette: "96 koli",
    //                 img: productImage1
    //             },
    //         ]
    //     },
    //     {
    //         id: "medikal-cop-posetleri",
    //         data: [
    //             {
    //                 no: 601,
    //                 category: "MEDİKAL-TIBBI ATIK",
    //                 title: "ORTA BOY",
    //                 product: "55x60 cm - 50 mikron",
    //                 roll: "300 gram - 10 torba",
    //                 parcel: "20 rulo - 0,00149 m³",
    //                 palette: "88 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 602,
    //                 category: "MEDİKAL-TIBBI ATIK",
    //                 title: "BÜYÜK BOY",
    //                 product: "65x80 cm - 50 mikron",
    //                 roll: "480 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0211 m³",
    //                 palette: "132 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 603,
    //                 category: "MEDİKAL-TIBBI ATIK",
    //                 title: "BATTAL BOY",
    //                 product: "72x95 cm - 50 mikron",
    //                 roll: "630 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0152 m³",
    //                 palette: "132 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 604,
    //                 category: "MEDİKAL-TIBBI ATIK",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 50 mikron",
    //                 roll: "800 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0169 m³",
    //                 palette: "132 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 605,
    //                 category: "MEDİKAL-TIBBI ATIK",
    //                 title: "BATTAL BOY",
    //                 product: "72x95 cm - 50 mikron",
    //                 roll: "630 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0152 m³",
    //                 palette: "132 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 606,
    //                 category: "MEDİKAL-TIBBI ATIK",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 50 mikron",
    //                 roll: "800 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0169 m³",
    //                 palette: "132 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 607,
    //                 category: "MEDİKAL-TIBBI ATIK",
    //                 title: "BATTAL BOY",
    //                 product: "72x95 cm - 50 mikron",
    //                 roll: "630 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0152 m³",
    //                 palette: "132 koli",
    //                 img: productImage1
    //             },
    //             {
    //                 no: 608,
    //                 category: "MEDİKAL-TIBBI ATIK",
    //                 title: "JUMBO BOY",
    //                 product: "80x110 cm - 50 mikron",
    //                 roll: "800 gram - 10 torba",
    //                 parcel: "10 rulo - 0,0169 m³",
    //                 palette: "132 koli",
    //                 img: productImage1
    //             },
    //         ]
    //     },
    // ]

    const getTitle = (id) => {
        switch (id) {
            case "temizlik-fircalari":
                return "TEMİZLİK FIRÇALARI";
            case "yersil-cekpaslar":
                return "YERSİL ÇEKPASLAR";
            case "camsil-cekcekler":
                return "CAMSİL ÇEKÇEKLER";
            case "supurgeli-faraslar":
                return "SÜPÜRGELİ FARAŞLAR"
            case "temizlik-kovalari":
                return "TEMİZLİK KOVALARI"
            case "mop-basliklar":
                return "MOP BAŞLIKLARI"
            case "diger-urunler":
                return "DİGER ÜRÜNLER"
            default:
              return "TEMİZLİK FIRÇALARI"
        }
    }

    return (
        <div className={styles.productPageContainer}>
            <div className={styles.breadcrumbContainer}>
                <div className={styles.breadcrumbMainContainer}>
                    <span onClick={() => navigate(`/`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>Anasayfa</span>
                    <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                    <span onClick={() => navigate(`/urunler`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }} >Urunler</span>
                    <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                    <span style={{ fontSize: "14px", fontWeight: 600, color: "#f2f2f2" }}>{getTitle(id)}</span>
                </div>
            </div>
            <div className={styles.pageTitleContainer}>
                <h1>{getTitle(id)}</h1>
            </div>
            <div className={styles.productContainer}>
                <div className={styles.productMainContainer}>
                    {
                        productDatas.map(value => {
                            if(value.id === id){
                                return(
                                    value.data.map(val => (
                                        <ProductBoxWithDetail key={val.no} name={value.id} data={val} title={val.title} image={val.img[0]} />
                                    ))
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductPage;